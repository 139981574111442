<template>
	<div @click="showDetail" class="wi100">
		<div class="pic-box uf uf-ac">
			<el-image :src="$cons.SERVER_PATH + service.logoUrl"
			          fit="fill" class="uf-f1" style="height: 120px;border-radius: 10px;overflow: hidden">
				<div slot="error">
					<div class="item-error">
						<div>
							{{
							service.serviceName && service.serviceName.length > 16 ? service.serviceName.substr(0, 15) + '...' :
							service.serviceName
							}}
						</div>
					</div>
				</div>
			</el-image>
			<div class="service-desc-box" style="width: 85%">
				<div class="top-right wi100 uf uf-ac uf-pa">
					<div>
						<el-button v-if="collection" type="text" :icon="'el-icon-star-on'" @click="removeCollect">已收藏
						</el-button>
						<el-button v-else type="text" :icon="'el-icon-star-off'"
						           @click.stop="collectionControl">收藏产品
						</el-button>
					</div>
					<div class="f14 op-07">
						<span>累计销量：</span><span>{{service.serviceCount}}</span>
					</div>
					<div class="f14 op-07">
						<span>累计评价：</span><span>{{service.itemComment}}</span>
					</div>
				</div>
				<div class="ctx-link fwb">
					<el-tooltip :content="service.serviceName" effect="light">
						<div class="service-name-wrapper">{{ service.serviceName }}</div>
					</el-tooltip>
				</div>
				<!--        <div class="price-box mt-1">-->
				<!--          <span v-if="service.minPrice !== service.maxPrice && service.minPrice !== -1"><span-->
				<!--              class="f20">￥</span>{{ (service.minPrice || 0).toFixed(2) }} - {{-->
				<!--              (service.maxPrice || 0).toFixed(2)-->
				<!--            }}</span>-->
				<!--          <span class="f18" v-else-if="service.minPrice === service.maxPrice && service.minPrice !== -1">-->
				<!--                      ￥{{ (service.minPrice || 0).toFixed(2) }}-->
				<!--                    </span>-->
				<!--          <span v-else class="f18">面议</span>-->
				<!--        </div>-->
				<div class="mt-1 mb-2">
					<div class="f14">
						<span class="op-07" style="line-height: 24px">所属行业：{{ service.itemName }}</span>
						<span></span>
					</div>
				</div>
				<div>
					<div class="f14">
						<span class="op-07">服务商：</span>
						<span class="c-base" :title="service.enterpriseName">{{ ellipsis(service.enterpriseName) }}</span>
						<span></span>
					</div>
				</div>
				<!--        <div class="extra-box">-->
				<!--          <div style="padding: 0 7px;border: 1px solid #007bff;border-radius: 3px;color: #007bff">-->
				<!--            自营-->
				<!--          </div>-->
				<!--          <div class="ml-2" style="padding: 0 7px;border: 1px solid #fe3b25;border-radius: 3px;color: #fe3b25">-->
				<!--            严选-->
				<!--          </div>-->
				<!--          <div class="ml-2" style="padding: 0 7px;border: 1px solid #fe3b25;border-radius: 3px;color: #fe3b25">-->
				<!--            服务质保-->
				<!--          </div>-->
				<!--        </div>-->
			</div>
		</div>
	</div>
</template>

<script>
export default {
  name: 'service-card',
  props: {
    service: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  computed: {
    //存取用户登录状态
    loginStatus: {
      get () {
        return this.$store.state.user.loginStatus
      }
    },
    userId: {
      get () {
        return this.$store.state.user.id
      }
    }
  },
  data () {
    return {
      collection: false
    }
  },
  created () {
    this.collection = this.service.collectList ? this.service.collectList.indexOf(this.userId) > -1 : false
  },
  methods: {
    showDetail () {
      this.$emit('showDetail')
    },
    collectionControl () {
      if (this.loginStatus) {
        this.$http({
          url: this.$http.adornUrl(this.$api.COLLECT.SAVE),
          method: 'POST',
          data: this.$http.adornData({
            'serviceId': this.service.id,
            'serviceName': this.service.serviceName,
            'itemCode': this.service.itemCode,
            'logoUrl': this.service.itemUrl
          })
        }).then(({data}) => {
          if (data && data.code === 0) {
            this.$message.success('已收藏')
            this.service.collectList.push(this.userId)
            this.collection = this.service.collectList.indexOf(this.userId) > -1
          }
        })
      } else {
        this.$message.warning('该功能需要登录')
      }
    },
    removeCollect () {
      this.$http({
        url: this.$http.adornUrl(this.$api.COLLECT.REMOVE),
        method: 'post',
        params: this.$http.adornParams({
          'serviceId': this.service.id
        })
      }).then(({data}) => {
        if (data && data.code === 0) {
          this.$message.info('已取消收藏')
          if (this.service.collectList.indexOf(this.userId) > -1) {
            this.service.collectList.splice(this.service.collectList.indexOf(this.userId), 1)
            this.collection = this.service.collectList.indexOf(this.userId) > -1
          }
        } else {
          this.$message.error(data.msg)
        }
      })
    },

    //控制字数
    ellipsis (value) {
      if (!value) return ''
      if (value.length > 12) {
        return value.slice(0, 12) + '...'
      }
      return value
    }
  }
}
</script>

<style scoped>
.price-box {
	font-size: 1.2rem;
	height: 1.5rem;
	line-height: 1.5rem;
	color: #fe3b25;
}

.pic-box {
	padding: 10px 15px;
	width: 100%;
	height: auto;
	margin-bottom: 10px;
	background-color: #FFFFFF;
	border: 1px solid #e5e5e5;
	border-radius: 16px;
	overflow: hidden;
	/*box-shadow: 0 2px 5px rgb(0 0 0 / 8%);*/
}

.pic-box:hover {
	box-shadow: 0 0px 8px rgba(0, 0, 0, .15);
	transform: translate3d(0, -2px, 0);
	cursor: pointer;
}

.item-error {
	/*width: 245px;*/
	height: 130px;
	background-image: url("../../assets/img/product/default_3.png");
	background-size: cover;
	font-size: 1.4rem;
	font-weight: 600;
	line-height: 2rem;
	padding: 40px 20px;
	color: #FFFFFF;
	overflow: hidden;
}

.service-desc-box {
	padding: 0px 10px 10px 30px;
	position: relative;
}

.service-desc-box .top-right {
	position: absolute;
	right: 0;
	top: 0;
	width: 40%;
}

.service-name-wrapper {
	line-height: 30px;
	overflow: hidden;
}

.extra-box {
	display: -webkit-flex !important;
	display: flex !important;
	justify-content: flex-start;
	position: relative;
	top: 10px;
	left: 0px;
	font-size: 10px;
}
</style>
